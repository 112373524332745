import React, { useEffect, useState } from 'react';

export default function GladlySidekick() {   
    const query = new URLSearchParams(window.location.search);
    const geo = query.get('geo');

    const getSidekickId = () => {
        switch ((geo ?? '').toLowerCase()) {
            case 'bh_au':
                return 'balsambrands-uat.com-au-OPT';
            case 'bh_de':
                return 'balsambrands-uat.com-de-OPT';
            case 'bh_fr':
                return 'balsambrands-uat.com-fr-OPT';
            case 'bh_uk':
                return 'balsambrands-uat.com-uk-OPT';
            case 'bh_ca':
                return 'balsambrands-uat.com-ca-OPT';
            default:
                return 'balsambrands-uat.com-us-OPT';
        }
    }

    useEffect(() => {
        console.log("useEffect triggered");

        let appId = getSidekickId();
        console.log("Found AppId: " + appId + " for geo: " + geo);

        window.gladlyConfig = {
            appId: appId
        };
        console.log("GladlyConfig set to use the AppId")

        window.loadGladlySidekick(window, document, 'Gladly');
        console.log("Gladly Sidekick should be loaded");
    });

    console.log("Rendering GladlySidekick React Component (doesn't neccessarily load the chat window)");

    return (
        <>
            
        </>
    );
}
